
import React, { useState, setState } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Chat, Channel, ChannelList, Window } from 'stream-chat-react';
import { ChannelHeader, MessageList } from 'stream-chat-react';
import { MessageInput, Thread } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
import config from "../auth_config.json";
import {
  CreateChannel,
  CustomMessage,
  MessagingChannelList,
  MessagingChannelPreview,
  MessagingInput,
  MessagingThreadHeader,
} from '../components';
import { ChannelInner } from '../components/ChannelInner/ChannelInner';

import 'stream-chat-react/dist/css/index.css';

import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const { apiOrigin } = config;
export const GiphyContext = React.createContext({});
export const ConnectedUserContext = React.createContext({});

export const ChatComponent = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  
  function getStreamClient(){
    const chatClientStream = new StreamChat('yjdda99qg54p');
    chatClientStream.setBaseURL("https://chat-proxy-dublin.stream-io-api.com");
    return chatClientStream
  }
  const [state, setState] = useState({
    chatClient: getStreamClient(),
    initDone: false,
    error: null,
  });

  var filters, sort; 

const [giphyState, setGiphyState] = useState(false);
const [connectedUser, setConnectedUser] = useState(false);
const [isCreating, setIsCreating] = useState(false);
const [isMobileNavVisible, setMobileNav] = useState(false);
const [theme, setTheme] = useState('dark');
const toggleMobile = () => setMobileNav(!isMobileNavVisible);
const giphyContextValue = { giphyState, setGiphyState };
const connectedUserContextValue = { connectedUser, setConnectedUser };

const channelOptions = { state: true, watch: true, presence: true};
const callApi = async () => {
  try {
    const token = await getAccessTokenSilently();

    const response = await fetch(`${apiOrigin}/athletes/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await response.json();
    setConnectedUser(responseData);
    console.log(connectedUser)

    filters  = { type: 'messaging', members: { $in: [responseData.user_id.toString()] } };
    sort  = { last_message_at: -1 };
    await state.chatClient.connectUser({id: responseData.user_id.toString()},
      async () => {
        // make a request to your own backend to get the token
        const responseToken = await fetch(`${apiOrigin}/athletes/get_stream_chat_token`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const tokenData = await responseToken.text();
        return tokenData;
    });
    const channels = await state.chatClient.queryChannels(filters, sort);
    console.log(channels)

    setState({
      ...state,
      initDone: true,
      error: null,
      filters:filters,
      sort:sort
    });
  } catch (error) {
    console.log(error);
    setState({
      ...state,
      error: error.error,
    });
  }
};

if(state.initDone == false){
  callApi();
}
  return (
    <>
    {state.initDone && (
      <Chat client={state.chatClient} theme={'messaging light'}>
      <ChannelList
        filters={state.filters}
        sort={state.sort}
        options={channelOptions}
        List={(props) => (
          <MessagingChannelList {...props} onCreateChannel={() => setIsCreating(!isCreating)} />
        )}
        Preview={(props) => <MessagingChannelPreview {...props} {...{ setIsCreating }} />}
      />
      <Channel
          Input={MessagingInput}
          maxNumberOfFiles={10}
          Message={CustomMessage}
          multipleUploads={true}
          ThreadHeader={MessagingThreadHeader}
          TypingIndicator={() => null}
        >
          {isCreating && (
            <CreateChannel toggleMobile={toggleMobile} onClose={() => setIsCreating(false)} connectedUser={connectedUser}/>
          )}
          <GiphyContext.Provider value={giphyContextValue}>
            <ChannelInner theme={theme} toggleMobile={toggleMobile} />
          </GiphyContext.Provider>
        </Channel>
    </Chat>
    )}
  </>
);
};

export default withAuthenticationRequired(ChatComponent, {
  onRedirecting: () => <Loading />,
});






